@import 'index';

.transition-container {
    display : flex;
    transition : 800ms;
    > div {
       width : 100%;
       min-width : 100%;
    }
    &.module-active {
        transform : translateX(-100%);
    }
}
.module-list {
    .link {
        cursor : pointer;
        text-decoration : underline;
        &:hover {
            color : #766df4;
        }
    }
}

.unit-container {
    &.in-unit {
        display : flex;
        .course {
            overflow : hidden;
        }
        .canvas-wrap {
            //margin-left : -25%;
            //margin-top : 12px;
            transform : scale(0.8) translateY(-18%);
            //transform : scale(0.8) translate(-30%, -18%);
            margin-left : -22%;
        }
    }
    .lesson-list-container {
        margin-left : -12%;
        max-width : 46vw;
        @media screen and (max-width : 992px) {
          margin-left : auto;
          max-width : 100%;
        }
    }
}

.lesson-prog-container {
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    border-left : 2px solid $primary;
    margin-left : 20px;
    .prog-item {
        align-items : center;
        display : flex;
        margin-bottom : 40px;
        &:last-child { margin-bottom : 0; }
        &.active {
            > div {
                background : $primary;
            }
        }
        > div {
            display : flex;
            align-items : center;
            justify-content : center;
            width : 25px;
            height : 25px;
            color : $primary;
            border : 2px solid;
            font-weight : 700;
            font-size : 15px;
            border-radius : 50%;
            margin-left : -14px;
            background : #fff;
            box-shadow : 1px 0px 8px -1px rgba(0, 0, 0, 0.2);
        }
        label {
            margin-left : 10px;
            opacity : 0.6;
            &.active-link {
                opacity : 1;
                cursor : pointer;
                &:hover {
                    color : $primary;
                }
            }
        }
    }
}
