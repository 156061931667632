.my-work {
    .panel {
        position : fixed;
        right : 10px;
        top : 105px;
        z-index : 10;
        background-color: #f7f7fc;
        border: none;
        //transition : 600ms;
       
        &.hidden {
            display: none;
            // transform : translate(20%, 20%) scale(0.6);
            // opacity : 0;
            // z-index : 0;
        }
        .card-header {
            justify-content : space-between;
            border: none;
            text-align: right;
            h4 { margin : 0;  }
        }
        .card-body {
            // padding-top : 1rem;
            padding: 0;
            max-height : calc(100vh - 180px);
            overflow : auto;
            .accordion-item {
                overflow : hidden;
            }
        }
        @media screen and (max-width : 992px) {
            top : 84px;
            left : 1rem;
            right : 1rem;
        }
    }
    .toggle {
        display : none;
        @media screen and (max-width : 992px) {
            position : fixed;
            bottom : 10px;
            right : 10px;
            display : flex;
            justify-content : center;
            align-items : center;
            width : 40px;
            height : 40px;
            border-radius : 50%;
            z-index : 10;
        }
    }
    .note-link {
        color: black;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden
    }
    .note-link:hover{
        cursor: pointer;
    }
}
.my-work .panel.card{
    @media screen and (max-width : 992px) {
        box-shadow: 1px 5px 15px #aaaaaa !important;
    
        padding: 0px 20px 20px;
    }

}