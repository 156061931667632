@import 'index';

.canvas-wrap {
    margin : 50px auto;
    position : relative;
    // width : 400px;
    #canvas-dom {
        position : absolute;
        top : 0;
        left : 0;
        right : 0;
        bottom : 0;
    }
    .unit-title {
        position : absolute;
        display : flex;
        transition : 200ms;
        cursor : pointer;
        &.inaccessible {
          pointer-events: none;
          label {
            opacity: 0.7;
          }
        }
        &.inactive {
           margin : 5px;
           label {
               opacity : 0.3;
               margin-top : -14px;
           }
           &:before {
              width : 10px;
              height : 10px;
              //margin-right : 5px;
           }
        }
        &.current {
            pointer-events : none;
            label {
                transform : scale(1.2) translateX(10%);
            }
            &:before {
                border: 1px solid $primary;
            }
        }
        &:before {
            content : attr(data-before);
            color: white;
            font-size: 10px;
            text-align: center;
            padding-top:2.5px;
            display : inline-block;
            margin-right : 10px;
            width : 20px;
            height : 20px;
            border-radius : 50%;
            background : #000;
            transition : 200ms;
        }
        &.active:before {
            background-image : radial-gradient(farthest-corner at 0px 0px, #35dfc3 0%, #29b6ce 100%);
        }
        &:hover {
            &:before {
                transform : scale(1.2);
            }
            label {
                transform : translateX(4px);
            }
        }
        label {
            display : block;
            padding : 8px 20px;
            background : $primary;
            color : #fff;
            border-radius : 50px;
            color : #fff;
            margin-top : -9px;
            cursor : pointer;
            transition : 200ms;
        }
    }
}
