
@import 'index';

/* talking circle */
@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translate3d(0px, 0px, 0px);
    }
    50% {
        box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
        transform: translate3d(0, -5px, 0);
    }
    100% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes floatReverse {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translatey(0px);
    }
    50% {
        box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
        transform: translatey(5px);
    }
    100% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translatey(0px);
    }
}

.conversation-space {
    width : 100%;
    margin-bottom : 20px;
    h3 {
        margin : 10px 0 30px;
        text-align : center;
    }
}
.conversation-space .circle-container {
    position : relative;
    display : flex;
    align-items : center;
    justify-content : center;
    margin : auto;
    width : 500px;
    height : 500px;
    font-family : arial;
}
.conversation-space .topic {
    position : absolute;
    display : flex;
    align-items : center;
    justify-content : center;
    padding : 13px 20px;
    //color : #665bfb;
    //width : 190px;
    //height : auto;
    //border-radius : 8px;
    //border : 2px solid;
    //font-weight : 600;
    font-size : 15px;
    //line-height : 1.2;
    text-align : center;
    transition : 200ms;
    i {
        margin-left : 6px;
    }
    &:hover {
        //color : darken(#665bfb, 10%);
    }
}
.conversation-space #mid-point {
    position : relative;
    width : 0px;
    height : 0px;
}
.conversation-space .student {
    position : absolute;
    display : flex;
    align-items : center;
    justify-content : center;
    background-size : cover;
    background-position : center;
    border-radius : 50%;
    //background-color : #154d6d;
    //background-color : #202258;
    background-color : darken($primary, 10%);
    color : #d5d5d5;
    font-size : 14px;
    cursor : pointer;
    transition : 150ms;
    will-change: transform;
    .ai-check {
        position : absolute;
        padding : 2px;
        top : 4%;
        right : 4%;
        border-radius : 50%;
        font-weight : 700;
        background : #fff;
        border : 1px solid;
        color : #10a310;
        font-size : 15px;
    }
    .comments {
        position : absolute;
        padding : 2px;
        top : 4%;
        left : 4%;
        height : 21px;
        width : 21px;
        text-align : center;
        border-radius : 50%;
        font-weight : 700;
        background : #fff;
        border : 1px solid;
        //color : #000;
        color : darken($primary, 20%);
        font-size : 13px;
    }
}
.conversation-space .name {
    position : absolute;
    bottom : -5px;
    //background-color : #0c2e42;
    background-color : darken($primary, 20%);
    font-size : 12px;
    padding : 3px 6px;
    border-radius : 50px;
    text-align : center;
    white-space : nowrap;
}
.conversation-space .student:hover {
    z-index : 1;
    background-color : #4c85a6;
    color : #fff;
}

.forum-mod {
    .prompt-msg {
      font-size: 20px;
    }

    .accordion-profile-icon {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .forum-profile-icons {
      position: absolute;
      margin-top: 10px;
    }

    .accordion p {
        margin-bottom: 0px;
    }
    .accordion-body {
        padding: 1.5rem 1.5rem;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .accordion ::after {
        display: none;
    }

    .accordion-item {
        border-right: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
}
