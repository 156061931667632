$primary : #766df4;

// Override theme
//$primary : #9f5050;
/*
$primary : #880E00;
.btn-primary, .bg-primary {
    background-color : $primary;
    border-color : $primary;
    &:hover, &:active {
        background-color : darken($primary, 8%);
        border-color : darken($primary, 8%);
    }
}
a {
    color : $primary;
}
.accordion-button:hover {
    color : $primary;
}
.breadcrumb-item>a:hover {
    color : $primary;
}

.navbar-dark {
    background : #541818;
    .ml-auto {
        margin-left : auto;
    }
    .nav-link {
        display : inline-flex;
        align-items : center;
    }
}
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#custom-buffer-div {
    height : 96px;
    @media screen and (max-width : 992px) {
        height : 62px;
    }
}

.unit-page {
    padding-top: 0 !important;
}

.breadcrumb {
  margin-top: 10px;
}
.breadcrumb-item {
  font-weight: normal;
  @media screen and (max-width : 992px) {
      font-size: 10px;
  }
}
.breadcrumb-item a {
  color: #aaa;
}

.current-course {
    border : 1px solid #ccc;
    margin : 20px;
    padding : 20px;
}

.upload-tests {
    display : flex;
    flex-direction : column;
    border : 1px solid #ccc;
    margin : 20px;
    padding : 20px;
}
.upload-tests div:first-child {
    margin-bottom : 50px;
}

#id_fnesc-custom-assignment, #id_fnesc-custom-quiz {
  display: none;
}

a.rdw-dropdown-selectedtext {
  color: black !important;
}
.wysiwyg-text-area{
  height: 200px;
}
.state{
  display: none;
}
.grade {
  display: none;
}
.questionflag{
  display: none;
}

.accesshide{
  display: none;
}
label.ml-1{
  padding-left : 5px !important;
}
.quiz-question{
  margin-bottom: 60px;
}
h3.no{
  display: none;
}
.qtext p {
  font-size: larger;
  font-weight: bold;
}
.answer select.form-select {
  margin: 0px 0px 5px 20px;
}
.sticky{
  margin-top: 100px;
}
.note-link p{
  margin: 0px;
}

button.accordion-button.collapsed {
  padding: 15px;
}

/* animations */

// main {
//   min-height: 100%;
//   display: grid;
//   grid-template: "main";
//   flex: 1;
//   position: relative;
//   overflow: hidden;
//   background-color: white;
// }

// main > div > * {
//   grid-area: main; /* Transition: make sections overlap on same cell */
//   flex: 1 1 auto;
//   background-color: white;
//   position: relative;
//   // min-height: 100vh; /* Was here for the transition */
// }

// main > div > :first-child {
//   z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
// }

/* Transitions */
// .next-exit-active {
//   animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
//   z-index: 0;
// }
//
// .next-enter-active {
//   animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
//   z-index: 1;
// }
//
// .prev-exit-active {
//   animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
//   z-index: 1;
// }
//
// .prev-enter-active {
//   animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
//   z-index: 0;
// }
//
// @keyframes leaveToLeft {
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(-25%);
//     filter: brightness(0.5);
//   }
// }
//
// @keyframes enterFromLeft {
//   from {
//     transform: translateX(-25%);
//     filter: brightness(0.5);
//   }
//   to {
//     transform: translateX(0);
//   }
// }
//
// @keyframes leaveToRight {
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(100%);
//   }
// }
//
// @keyframes enterFromRight {
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(0);
//   }
// }

.answer div {
  display: flex;
}
.answer input {
  margin-right: 10px;
}
.qtype_multichoice_clearchoice{
  display: none;
}

.vendiagram{
  max-width:99%; width:900px;  margin:0 auto;   padding: 0 6%;

  /* this is where the magic starts to happen */
  display:flex;
  justify-content: space-around;
  align-items: center;
  aspect-ratio: 8 / 5; /* best supporting actor? */
  background-blend-mode:  overlay; /* <-- honestly, the star of show */
	background-image: radial-gradient(  circle at 33% 50%,
                      rgba(38, 225, 233, .5)  38%,
                      #233434 38%,
                      #233434 39%,
                      transparent 39%,
                      transparent
                    ),
                    radial-gradient( circle at 66% 50%,
                      rgba(230, 90, 155, .5)  39%,
                      #233434 39%,
                      #233434 40%,
                      transparent 40%,
                      transparent
                    );

		}
.vendiagram > * {
	flex: 1 0 33%;
	text-align: center;
}
// @media(min-width:320px){
//   html{
//     font-size: calc(14px + 16 * ((100vw - 320px) / 671));
//   }
// }
// @media(min-width:991px){
//   html{
//     font-size:30px;
//   }
// }

// /* Aspect Ratio Fall-back */
// @supports not (aspect-ratio:8/5){
//   .vendiagram{
//     padding-top:12.5%;
//     padding-bottom:12.5%;
//     /* By the power of guess and check, I pronounce this good enough,
//     that is to say,
//     12.5 is just a random magic number.
//     I have not seen this on a real iPhone yet  */
//   }
